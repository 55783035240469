import React, { useState } from 'react';
import { useSpring } from 'react-spring';
import Container from './container';
import Navigation from './navigation';
import Footer from './footer';
import Seo from './seo';
import ScrollBar from './elements/scrollBar';
import CookieConsent, { Cookies } from "react-cookie-consent";

function MenuNav({menuItems, brandLogo, activeItem}) {
	const [fullMenuVisible, setFullMenuVisible] = useState(false);
  
	const fullMenuAnimation = useSpring({
	  transform: fullMenuVisible ? `translateY(0)` : `translateY(-100%)`,
	  opacity: fullMenuVisible ? 1 : 0
	});
  
	return (
	  <div className="App">
		<button
		  className={`hamburger-menu-button ${fullMenuVisible ? "active" : ""}`}
		  onClick={() => setFullMenuVisible(!fullMenuVisible)}
		>
			<div className="header-menu-button-icon"></div>
		</button>
  
		<Navigation menuItems={menuItems} brandLogo={brandLogo} style={fullMenuAnimation} activeItem={activeItem} />
	  </div>
	);
  }

class Template extends React.Component {
	render() {
		const { 
			title,
			metaDescription,
			children,
			menuItems,
			footerItems,
			linkedIn,
			facebook,
			youtube,
			instagram,
			brandLogo,
			brandLogoFooter,
			activeItem
		} = this.props

		if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
			rootPath = __PATH_PREFIX__ + `/`
		}
		
		return (
			<Container>
				<ScrollBar />
				<Seo description={metaDescription} title={title} />
				<MenuNav key={menuItems.id} menuItems={menuItems} brandLogo={brandLogo} activeItem={activeItem} />
					<div className="content-container" style={{ background: '#fff' }}>
						{children}
					</div>
				<Footer key={footerItems.id} footerItems={footerItems} linkedIn={linkedIn} facebook={facebook} instagram={instagram} youtube={youtube} brandLogoFooter={brandLogoFooter} />
				<CookieConsent
					location="bottom"
					buttonText="Oké, sluiten"
					cookieName="myAwesomeCookieName2"
					style={{ background: "#e62146" }}
					buttonStyle={{ color: "#ffffff", fontSize: "18px" }}
					expires={150}
				>Deze website gebruikt cookies. Door OK te kiezen of gebruik te maken van deze website geeft u hiervoor toestemming. Klik hier voor de <a href="https://assets.ctfassets.net/kqmpmnkj1u32/4Wd8cX6UkAMl03B9tfVsMH/35a1c18ea4ef0f2dfa8719cbe4b24a79/Cookie-Statement.pdf" target="_blank">Cookie Policy</a>.</CookieConsent>
			</Container>
		)
	}
}

export default Template;
