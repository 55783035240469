import React, { useState }  from 'react';
import { Link } from 'gatsby';
import useDocumentScrollThrottled from './useDocumentScrollThrottled';
import { graphql } from 'gatsby';
import { animated } from 'react-spring';

class MenuItems extends React.Component {
	render() {
		return (
			<nav role="navigation">
				<ul className='navigation'>
				{this.props.menuItems ?
					this.props.menuItems.map(menuItem => (
						<li className={`navigationItem ${menuItem.title == this.props.activeItem ? 'active' : ''} ${menuItem.subItems ? "sub" : ''} ${Array.isArray(menuItem.subItems) && menuItem.subItems.length > 0 ? menuItem.subItems.map(subItem => (subItem.title == this.props.activeItem ? ' active ' : '' )) : ''}`}>
							<Link key={menuItem.id} to={menuItem.url}>{menuItem.title}</Link>
							{Array.isArray(menuItem.subItems) && menuItem.subItems.length > 0 ?
								<div className='submenu'>
									<ul className='submenu-items'>
										{menuItem.subItems.map(subItem => (
											<li className={`navigationItem ${subItem.title == this.props.activeItem ? 'active' : ''}`}>
												<Link key={subItem.id} to={subItem.url}>{subItem.title}</Link>
											</li>))}
									</ul>
								</div> : ''
								}
						</li>
					)) : ''}
				</ul>
			</nav>
		)
	}
}

function Header({menuItems, style, brandLogo, activeItem}) {
	const [shouldHideHeader, setShouldHideHeader] = useState(false);
	const [shouldShowSmall, setShouldShowShadow] = useState(false);

	const MINIMUM_SCROLL = 80;
	const TIMEOUT_DELAY = 400;

	useDocumentScrollThrottled(callbackData => {
		const { previousScrollTop, currentScrollTop } = callbackData;
		const isScrolledDown = previousScrollTop < currentScrollTop;
		const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

		setShouldShowShadow(currentScrollTop > 2);

		setTimeout(() => {
			setShouldHideHeader(isScrolledDown && isMinimumScrolled);
		}, TIMEOUT_DELAY);
	});

	const smallStyle = shouldShowSmall ? 'small' : '';
	const hiddenStyle = shouldHideHeader ? 'hidden' : '';
	return (
		<div className={`header ${smallStyle} ${hiddenStyle}`}>
			<div className='logo'><Link to='/'><img src={brandLogo}/></Link></div>
			<animated.div className="hamburger-menu menu-full" style={style}>
				<MenuItems menuItems={menuItems} key={menuItems.id} activeItem={activeItem} />
			</animated.div>
			<div className="desktop-menu">
				<MenuItems menuItems={menuItems} key={menuItems.id} activeItem={activeItem}/>
			</div>
		</div>
	);
}

export default Header;

export const query = graphql`
	fragment MainMenu on ContentfulMainMenu {
		title
		menuItems {
			id
			title
			url
			subItems {
				id
				title
				url
			}
		}
		brandLogo {
			title
			description
			file {
				url
			}
		}
	}
`;