import React from 'react';
import facebookLogo from '../assets/images/facebook.svg';
import instagramLogo from '../assets/images/instagram.svg';
import linkedinLogo from '../assets/images/linkedin.svg';
import youtubeLogo from '../assets/images/youtube.svg';
import { Link, graphql } from 'gatsby';

class FooterItems extends React.Component {
	render() {
		return (
			<div className='website'>
				{
					this.props.footerItems.map((i) =>
						<Link key={i.id} target={i.file ? "_blank" : "_self"} to={i.file ? i.file.file.url : i.url}>{i.title}</Link>
					)
				}
			</div>
		)
	}
}

export default ({
	footerItems,
	linkedIn,
	instagram,
	facebook,
	youtube,
	brandLogoFooter
}) => (
	<div className='footer'>
		<div className='logo'>
			<img src={brandLogoFooter} />
		</div>
		<div className='links'>
			<div className='social'>
				{linkedIn ? <a href={linkedIn} className='social-img' target="_blank"><img src={linkedinLogo} /></a> : ''}
				{instagram ? <a href={instagram} className='social-img' target="_blank"><img src={instagramLogo} /></a> : ''}
				{facebook ? <a href={facebook} className='social-img' target="_blank"><img src={facebookLogo} /></a> : ''}
				{youtube ? <a href={youtube} className='social-img' target="_blank"><img src={youtubeLogo} /></a> : ''}
			</div>
			<FooterItems footerItems={footerItems} key={footerItems.id} />
		</div>
	</div> 
)

export const query = graphql`
	fragment Footer on ContentfulFooterMenu {
		title
		footerItems {
			id
			title
			url
			file {
				file {
					url
				}
			}
		}
		facebook
		instagram
		linkedIn
		youtube
		brandLogoFooter {
			title
			description
			file {
				url
			}
		}
	}
`;